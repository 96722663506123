import React, { useState } from 'react'
import PropTypes from 'helpers/proptypes' // components
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Collapse, AnimatedChevron } from '@vizeat/components/es6/components/Collapse'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Heading } from '@vizeat/components/es6/components/Heading'

export function MobileCollapseColumn({ title, children }) {
  const isTablet = useMediaQuery('tablet')
  const [expanded, setExpanded] = useState(false)

  return (
    <Flex flex={1} gap='24px' direction='column' maxHeight='unset'>
      {isTablet ? (
        <>
          <Heading as='h2' color='white' type='medium' style={{ marginBottom: '0' }}>
            {title}
          </Heading>
          {children}
        </>
      ) : (
        <Collapse
          isExpanded={expanded}
          renderTrigger={(expanded) => (
            <Flex alignItems='center'>
              <Heading
                as='h2'
                color='white'
                type='medium'
                style={{
                  fontSize: '14px',
                  marginBottom: 0,
                }}
              >
                {title}
              </Heading>
              <AnimatedChevron ml='8px' expanded={expanded} size='16px' color='primary' />
            </Flex>
          )}
          onToggle={() => setExpanded(!expanded)}
        >
          {children}
        </Collapse>
      )}
    </Flex>
  )
}

MobileCollapseColumn.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
