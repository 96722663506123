// libs
import dynamic from 'next/dynamic'
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
// helpers
import isUndefined from 'lodash/isUndefined'
import { konstants } from '@vizeat/helpers'
// components
import NextHead from 'next/head'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Box } from '@vizeat/components/es6/components/Box'
const { DEFAULT_CURRENCY, SIGN_UP_CAMPAIGN } = konstants
// dynamic imports
const ExitModal = dynamic(() => import('components/shared/modals/ExitModal').then((mod) => mod.ExitModal), {
  ssr: false,
})
const HealthAndSafetyModal = dynamic(
  () => import('components/shared/modals/HealthAndSafetyModal').then((mod) => mod.HealthAndSafetyModal),
  { ssr: false },
)
const SignupBanner = dynamic(() => import('components/shared/banners/SignupBanner').then((mod) => mod.SignupBanner), {
  ssr: false,
})
const CouponBanner = dynamic(() => import('components/shared/banners/CouponBanner').then((mod) => mod.CouponBanner), {
  ssr: false,
})
const HostApplicationBanner = dynamic(
  () => import('components/shared/banners/HostApplicationBanner').then((mod) => mod.HostApplicationBanner),
  { ssr: false },
)
const NewsletterSubscriptionModal = dynamic(
  () => import('components/shared/modals/NewsletterSubscriptionModal').then((mod) => mod.NewsletterSubscriptionModal),
  {
    ssr: false,
  },
)

export function DefaultLayout({ children, showExitModal, showSignupBanner, title, ...props }) {
  const { t } = useTranslation()
  const router = useRouter()
  const matchDesktop = useMediaQuery('desktop')
  const shouldShowNewsletterSubscription = !isUndefined(router.query.show_newsletter_subscription)
  const shouldShowExitModal = showExitModal && !shouldShowNewsletterSubscription && matchDesktop
  const shouldShowCouponBanner = !isUndefined(router.query.showCouponBanner)
  const shouldShowSignupBanner = showSignupBanner && SIGN_UP_CAMPAIGN.isActive && matchDesktop

  function toggleNewsletterSubscriptionModal() {
    const { show_newsletter_subscription: showNewsletterSubscription, ...queryParameters } = router.query
    router.replace({ pathname: router.pathname, query: queryParameters })
  }

  return (
    <Box bg='lighterGray' {...props}>
      {title && (
        <NextHead>
          <title>{title}</title>
        </NextHead>
      )}
      <HostApplicationBanner />
      {shouldShowCouponBanner && (
        <CouponBanner
          show
          delay={0}
          coupon={{
            code: 'GTRIPS',
            amount: 1000,
            currency: DEFAULT_CURRENCY,
          }}
        />
      )}
      {children}
      {shouldShowSignupBanner && <SignupBanner />}
      {shouldShowNewsletterSubscription && (
        <NewsletterSubscriptionModal
          handleClose={toggleNewsletterSubscriptionModal}
          imageId={1477578}
          title={t('NewsletterSubscriptionModal::Sign up to our newsletter for the latest news from Eatwith')}
          show={shouldShowNewsletterSubscription}
          subtitle={t(
            "NewsletterSubscriptionModal::We'll share special offers, unforgettable culinary experiences & exciting updates you won't want to miss!",
          )}
        />
      )}
      {shouldShowExitModal && <ExitModal />}
      <HealthAndSafetyModal />
    </Box>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showExitModal: PropTypes.bool,
  showSignupBanner: PropTypes.bool,
  title: PropTypes.string,
}

DefaultLayout.defaultProps = {
  showExitModal: true,
  showSignupBanner: true,
  title: undefined,
}
