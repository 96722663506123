import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { MobileCollapseColumn } from './MobileCollapseColumn'
import { SmartFooterLink } from './SmartFooterLink'

const MOST_POPULAR_DESTINATIONS = [
  { href: '/l/destinations/paris', content: __('Footer::SEO::Paris') },
  { href: '/l/destinations/rome', content: __('Footer::SEO::Rome') },
  { href: '/l/destinations/barcelona', content: __('Footer::SEO::Barcelona') },
  { href: '/l/destinations/new-york', content: __('Footer::SEO::New York') },
  { href: '/l/destinations/san-francisco', content: __('Footer::SEO::San Francisco') },
  { href: '/l/destinations/chicago', content: __('Footer::SEO::Chicago') },
  { href: '/l/destinations/london', content: __('Footer::SEO::London') },
  { href: '/l/destinations/lisbon', content: __('Footer::SEO::Lisbon') },
  { href: '/l/destinations/amsterdam', content: __('Footer::SEO::Amsterdam') },
]

const MOST_SEARCHED_EXPERIENCES = [
  {
    href: { pathname: '/search', query: { q: 'Paris, France', mealtypes: 'Cooking classes' } },
    content: __('Footer::SEO::Cooking classes in Paris'),
  },
  {
    href: { pathname: '/search', query: { q: 'Rome, Italy', mealtypes: 'Food walk' } },
    content: __('Footer::SEO::Food tours in Rome'),
  },
  {
    href: { pathname: '/search', query: { q: 'New York, USA', mealtypes: 'Brunch' } },
    content: __('Footer::SEO::Rooftop brunches in New York'),
  },
  { href: { pathname: '/search', query: { q: 'London, UK' } }, content: __('Footer::SEO::Supper clubs in London') },
  {
    href: { pathname: '/search', query: { q: 'Rome, Italy', mealtypes: 'Cooking classes' } },
    content: __('Footer::SEO::Pasta-making classes in Rome'),
  },
  {
    href: { pathname: '/search', query: { q: 'Amsterdam, Netherlands' } },
    content: __('Footer::SEO::Canal house dining in Amsterdam'),
  },
]

const StyledList = styled((props) => <Box as='ul' px='0' mb={{ default: '24px', desktop: '0' }} {...props} />)`
  list-style: none;
  line-height: 28px;
  columns: 2;
`

export function SEOSection() {
  const { t } = useTranslation()

  return (
    <Flex direction={{ default: 'column', tablet: 'row' }}>
      <MobileCollapseColumn title={t('Footer::Most popular destinations')}>
        <StyledList
          css={`
            ${({ theme }) => theme.media.tablet`
              columns: 3;
            `}
          `}
        >
          {MOST_POPULAR_DESTINATIONS.map(({ href, content }, index) => (
            <li key={index}>
              <SmartFooterLink href={href}>{t(content)}</SmartFooterLink>
            </li>
          ))}
        </StyledList>
      </MobileCollapseColumn>

      <MobileCollapseColumn title={t('Footer::Most searched experiences')}>
        <StyledList>
          {MOST_SEARCHED_EXPERIENCES.map(({ href, content }, index) => (
            <li key={index}>
              <SmartFooterLink href={href}>{t(content)}</SmartFooterLink>
            </li>
          ))}
        </StyledList>
      </MobileCollapseColumn>
    </Flex>
  )
}
