import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { MobileCollapseColumn } from './MobileCollapseColumn'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Image } from '@vizeat/components/es6/components/Image'
import { ButtonLink } from 'components/shared/ButtonLink'
import { JoinOurTable } from './JoinOurTable'
import { Availability } from './Availability'
import { SmartFooterLink } from './SmartFooterLink'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'

const StyledList = styled((props) => <Box as='ul' px='0' mb={{ default: '24px', desktop: '0' }} {...props} />)`
  list-style: none;
  line-height: 28px;
  columns: 2;

  ${({ theme }) => theme.media.tablet`
    columns: 1;
  `}
`

function SupportTrigger({ children }) {
  return (
    <ButtonLink
      clear
      className='support-trigger'
      css={`
        color: ${({ theme }) => theme.colors.white};
        padding: 0;
        font-size: 14px;
        &:hover:not([disabled]) {
          color: ${({ theme }) => theme.colors.white};
        }
      `}
    >
      {children}
    </ButtonLink>
  )
}

SupportTrigger.propTypes = {
  children: PropTypes.node.isRequired,
}

function EatwithLogo() {
  return (
    <Flex gridArea='icon' justifyContent='center' mb='36px'>
      <Image width='132px' src='/assets/images/logo-eatwith-intl-2022-white.svg' alt='Eatwith logo' />
    </Flex>
  )
}

const EATWITH_LINKS = [
  { href: '/landings/become-host/become-a-host-on-eatwith', content: __('Footer::Become a host') },
  { href: '/search', content: __('Footer::Experiences') },
  { href: '/landings/private-events', content: __('Footer::Private Events') },
  {
    href: 'https://intercom.help/eatwith/faq-english/refer-a-friend-give-10-get-10',
    content: __('Footer::Refer a friend'),
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  { href: '/landings/travel-agents', content: __('Footer::Travel Agents') },
  { href: '/l/affiliations', content: __('Footer::Become an affiliate') },
]

const COMPANY_LINKS = [
  { href: '/trust', content: __('Footer::Trust') },
  { href: '/pages/jobs', content: __('Footer::Jobs') },
  { href: '/about-us', content: __('Footer::About us') },
  {
    href: '/blog',
    content: __('Footer::Blog'),
    target: '_blank',
    rel: 'noopener noreferrer',
    locale: false,
  },
  { href: '/press', content: __('Footer::Press') },
]

export function LinksSection({ hideExternalLinks }) {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('desktop')

  if (hideExternalLinks) return <Availability />

  return (
    <Grid
      gap={{ desktop: '36px', tablet: '16px' }}
      templateAreas={{
        desktop: `
          "eatwith company help availability join"
        `,
        tablet: `
          "icon icon"
          "join join"
          "eatwith company"
          "help availability"
        `,
        default: `
          "icon"
          "join"
          "eatwith"
          "company"
          "help"
          "availability"
        `,
      }}
      templateColumns={{ default: '1fr', tablet: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr) 1.5fr 2fr' }}
    >
      {isMobile && <EatwithLogo />}

      <Box gridArea='eatwith'>
        <MobileCollapseColumn title={t('Footer::Eatwith')}>
          <StyledList>
            {EATWITH_LINKS.map(({ href, content, ...smartLinkProps }) => (
              <li key={href}>
                <SmartFooterLink href={href} {...smartLinkProps}>
                  {t(content)}
                </SmartFooterLink>
              </li>
            ))}
          </StyledList>
        </MobileCollapseColumn>
      </Box>
      <Box gridArea='company'>
        <MobileCollapseColumn title={t('Footer::Company')}>
          <StyledList>
            {COMPANY_LINKS.map(({ href, content, ...smartLinkProps }) => (
              <li key={href}>
                <SmartFooterLink href={href} {...smartLinkProps}>
                  {t(content)}
                </SmartFooterLink>
              </li>
            ))}
          </StyledList>
        </MobileCollapseColumn>
      </Box>
      <Box gridArea='help'>
        <MobileCollapseColumn title={t('Footer::Help & Support')}>
          <StyledList
            css={`
              columns: 1;
            `}
          >
            <li>
              <SmartFooterLink href='/how-it-works'>{t('Footer::How it works')}</SmartFooterLink>
            </li>
            {/* The className 'support-trigger' is registered in intercom settings as a `custom_launcher_selector` (see src/intercom.js intercomSettings) */}
            <li>
              <SupportTrigger>{t('Footer::FAQ')}</SupportTrigger>
            </li>
            <li>
              <SupportTrigger>{t('Footer::Contact us')}</SupportTrigger>
            </li>
          </StyledList>
        </MobileCollapseColumn>
      </Box>
      <Availability />
      <JoinOurTable />
    </Grid>
  )
}

LinksSection.propTypes = {
  hideExternalLinks: PropTypes.bool,
}

LinksSection.defaultProps = {
  hideExternalLinks: false,
}
