import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { Section } from '@vizeat/components/es6/components/Section'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { SocialLinks } from './SocialLinks'
import { LinksSection } from './LinksSection'
import { SEOSection } from './SEOSection'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'

const FooterDivider = styled(Divider)`
  transform: scaleY(0.5);
`

export function Footer({ hideExternalLinks }) {
  const isDesktop = useMediaQuery('desktop')

  if (!isDesktop) return null

  return (
    <Section
      as='footer'
      bg='darkBlue'
      py={{ default: '32px', desktop: '60px' }}
      px='24px'
      innerContainerStyles='padding: 0;'
    >
      <LinksSection hideExternalLinks={hideExternalLinks} />

      {isDesktop && !hideExternalLinks && (
        <>
          <FooterDivider m='28px 0 40px' color='white' />
          <SEOSection />
        </>
      )}

      <FooterDivider m={{ default: '40px 0 32px', tablet: '40px 0' }} color='white' />
      <SocialLinks hideExternalLinks={hideExternalLinks} />
    </Section>
  )
}

Footer.propTypes = {
  hideExternalLinks: PropTypes.bool,
}

Footer.defaultProps = {
  hideExternalLinks: false,
}
