import React from 'react'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import { useLocale } from 'hooks/locales/useLocale'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { Text } from '@vizeat/components/es6/components/Text'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { IconMail } from '@vizeat/components/es6/assets/icons'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { SmartFooterLink } from './SmartFooterLink'

export function Availability() {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const { appCurrencyIso3 } = useCurrency()
  const isTablet = useMediaQuery('tablet')

  return (
    <Box gridArea='availability'>
      {!isTablet && (
        <Divider
          color='white'
          marginTop='8px'
          marginBottom='40px'
          // transform scale keep the original margin spaces
          css={`
            transform: scaleY(0.5);
          `}
        />
      )}
      {isTablet && (
        <Heading
          as='h2'
          type='small'
          locale={locale}
          color='white'
          mt='0'
          mb='24px'
          fontSize='18px'
          lineHeight='24px'
          customEndingMarks
        >
          {t('Footer::when to contact us?')}
        </Heading>
      )}

      <Flex flexDirection='column'>
        <Text as='span' my='4px' size='s' color='white'>
          {appCurrencyIso3 === 'ILS'
            ? t('Footer::Israel::Mon-Fri 11AM-5PM {{timezone}}', { timezone: moment().isDST() ? 'IDT' : 'IST' })
            : t('Footer::Mon-Fri 10AM-4PM {{timezone}}', { timezone: moment().isDST() ? 'CEST' : 'CET' })}
        </Text>

        <Flex flexDirection='row' color='white' alignItems='center' my='4px'>
          <IconMail size='24px' mr='4px' />
          <SmartFooterLink href='mailto:support@eatwith.com'>support@eatwith.com</SmartFooterLink>
        </Flex>
      </Flex>
    </Box>
  )
}
