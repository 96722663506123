import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { IconPaperAirplane } from '@vizeat/components/es6/assets/icons'
import { Image } from '@vizeat/components/es6/components/Image'
import { Text } from '@vizeat/components/es6/components/Text'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { RoundedIconWrapper } from 'components/shared/RoundedIconWrapper'
import { MemberSubscriptionForm } from 'components/shared/forms'
import { SmartFooterLink } from './SmartFooterLink'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useIsRtl } from 'hooks/locales/useIsRtl'

const TitleHeading = styled(Text)`
  font-size: 28px;
  color: white;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 0;

  &:after {
    content: '!';
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledSmartLink = styled(SmartFooterLink)`
  display: inline !important;
  font-size: 12px !important;
`

const inputForwardedStyles = css`
  border: none;
  background: transparent;
  padding: 14px 20px;
  font-size: 14px;
  ${({ theme }) => theme.media.desktop`
    min-height: 40px;
  `}
`

function FormBlock() {
  const { t } = useTranslation()

  return (
    <Box maxWidth={{ default: '250px', desktop: '260px' }} m={{ default: 'auto', desktop: '0' }}>
      <MemberSubscriptionForm
        placeholderText={t('ExitModal::Your email address')}
        inputForwardedStyles={inputForwardedStyles}
        submitButton={
          <RoundedIconWrapper
            display='flex'
            forwardedAs='button'
            type='submit'
            bg='eatwithOrange'
            color='white'
            width={{ default: '40px', desktop: '36px' }}
            height={{ default: '40px', desktop: '36px' }}
            // to avoid the change to eatwith orange on hover
            css={`
              &:hover:not([disabled]) {
                color: ${({ theme }) => theme.colors.white};
              }
            `}
          >
            <IconPaperAirplane size='20px' />
          </RoundedIconWrapper>
        }
        flex='1'
        gridTemplateColumns={{ default: '1fr 40px', desktop: '1fr 36px' }}
        width='100%'
        margin={{ default: 'auto', desktop: '0' }}
        bg='white'
        border='none'
        borderRadius='xxl'
        padding='0 4px'
        fontSize='14px'
        fontWeight='medium'
        alignItems='center'
      />
      <Text
        size='xs'
        color='white'
        mt='8px'
        mb='0'
        textAlign={{ default: 'center', desktop: 'left' }}
        css={`
          line-height: 20px;
        `}
      >
        <Trans
          i18nKey={__(
            "Home::Newsletter::By signing up, I agree to VizEat's <termsAndConditionsURL>Terms & Conditions</termsAndConditionsURL>, <trustURL>Trust</trustURL> and <privacyPolicyURL>Privacy Policy</privacyPolicyURL>",
          )}
          components={{
            termsAndConditionsURL: <StyledSmartLink href='/terms' />,
            trustURL: <StyledSmartLink href='/trust' />,
            privacyPolicyURL: <StyledSmartLink href='/privacy' />,
          }}
        />
      </Text>
    </Box>
  )
}

function MobileLayout() {
  const { t } = useTranslation()
  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <Text
        color='white'
        size='xl'
        fontWeight='bolder'
        mb='16px'
        css={`
          &:after {
            content: '!';
            color: ${({ theme }) => theme.colors.primary};
          }
        `}
      >
        {t('Home::Newsletter::Subscribe to our newsletter')}
      </Text>
      <Text size='s' color='white' textAlign='center' mt='0' mb='32px'>
        {t('Home::Newsletter::Receive updates on our food experiences, promotions and more!')}
      </Text>
    </Flex>
  )
}

function DesktopLayout() {
  const { t } = useTranslation()
  const { isRTL } = useIsRtl()

  return (
    <Flex alignItems='center'>
      <Box>
        <TitleHeading>{t('Home::Newsletter::Subscribe to our newsletter')}</TitleHeading>
        <Text size='s' color='white' my='16px'>
          {t('Home::get our offers')}
        </Text>
      </Box>
      <Image
        src='/assets/images/arrow-spiral.png'
        width={70}
        height={70}
        alt=''
        css={`
          transform: scaleX(${isRTL ? '1' : '-1'}) rotate(160deg);
          margin-left: 8px;
        `}
      />
    </Flex>
  )
}

export function JoinOurTable() {
  const isDesktop = useMediaQuery('desktop')

  return (
    <Box gridArea='join'>
      {isDesktop ? <DesktopLayout /> : <MobileLayout />}
      <FormBlock />
      {!isDesktop && (
        <Divider
          color='white'
          marginTop='40px'
          marginBottom='24px'
          // transform scale keep the original margin spaces
          css={`
            transform: scaleY(0.5);
          `}
        />
      )}
    </Box>
  )
}
